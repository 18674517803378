<template>
  <div class="item-bar">
    <div class="item-bar-unit name">姓名</div>
    <div
      class="item-bar-unit ahi"
      @mouseenter="onAHIMouseOver"
      @mouseleave="onAHIMouseLeave"
    >
      <button class="item-bar-unit-container">
        <p>AHI/OAHI</p>
        <img class="item-bar-unit-container-icon" src="@imgs/para.png" />
      </button>
      <pick-list
        class="item-bar-ahi-list"
        :pickArray="AHILevelArray"
        :isShow="isShowAHILevelList"
        :selectedIndex="currentAHILevelIndex"
        @onListItemClick="onAHILevelItemClick"
      ></pick-list>
    </div>
    <div class="item-bar-unit sn">设备SN</div>
    <div class="item-bar-unit create-time">报告生成时间</div>
    <div class="item-bar-unit organization">机构</div>
    <div
      class="item-bar-unit completeness"
      @mouseenter="onCompletenessMouseOver"
      @mouseleave="onCompletenessMouseLeave"
    >
      <button class="item-bar-unit-container">
        <p>完整性</p>
        <img class="item-bar-unit-container-icon" src="@imgs/para.png" />
      </button>
      <pick-list
        class="item-bar-completeness-list"
        :pickArray="completenessArray"
        :isShow="isShowCompletenesslList"
        :selectedIndex="currentCompletenessIndex"
        @onListItemClick="onCompletenessItemClick"
      ></pick-list>
    </div>
    <div class="item-bar-unit operation">操作</div>
  </div>
</template>

<script>
import PickList from "@c/common/pick-list.vue";
export default {
  components: {
    PickList,
  },

  props: {
    selectedAHILevelIndex: Number,
    selectedCompletenessIndex: Number,
  },

  data: function () {
    return {
      isShowAHILevelList: false,
      isShowCompletenesslList: false,
      currentAHILevelIndex: 0,
      currentCompletenessIndex: 0,
    };
  },

  watch: {
    selectedAHILevelIndex: function (newValue) {
      this.currentAHILevelIndex = newValue;
    },

    selectedCompletenessIndex: function (newValue) {
      this.currentCompletenessIndex = newValue;
    },
  },

  computed: {
    AHILevelArray: function () {
      return getObjectKeys(AHI_LEVEL_CODE);
    },

    completenessArray: function () {
      return getObjectKeys(REPORT_COMPLETENESS_REQUEST_CODE);
    },
  },

  methods: {
    onAHIMouseOver: function () {
      this.isShowAHILevelList = true;
    },

    onAHIMouseLeave: function () {
      this.isShowAHILevelList = false;
    },

    onAHILevelItemClick: function (index) {
      this.currentAHILevelIndex = index;
      this.isShowAHILevelList = false;
      this.$emit("onAHILevelItemClick", index);
    },

    onCompletenessMouseOver: function () {
      this.isShowCompletenesslList = true;
    },

    onCompletenessMouseLeave: function () {
      this.isShowCompletenesslList = false;
    },

    onCompletenessItemClick: function (index) {
      this.currentCompletenessIndex = index;
      this.isShowCompletenesslList = false;
      this.$emit("onCompletenessItemClick", index);
    },
  },
};
</script>

<style lang="scss" scoped>


.item-bar {
  background-color: #fafafa;
  border-bottom: rem(1) solid #e8e8e8;
  width: 100%;
  height: rem(54);
  position: relative;
  display: flex;

  &-unit {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-container {
      display: flex;
      align-items: center;

      &-icon {
        width: rem(13);
        height: rem(12);
        margin-left: rem(4);
      }
    }
  }

  &-ahi-list {
    width: 8.5%;
    position: absolute;
    left: 7.5%;
    top: rem(54);
  }

  &-completeness-list {
    width: 14%;
    position: absolute;
    left: 66.8%;
    top: rem(54);
  }

  .name {
    width: 7.0%;
  }

  .ahi {
    width: 9.5%;
  }

  .sn {
    width: 13%;
  }

  .create-time {
    width: 17%;
  }

  .organization {
    width: 20%;
  }

  .completeness {
    width: 14%;
  }

  .operation {
    width: 19.5%;
  }
}
</style>